.App {
  text-align: center;
}
.swiper-slide {
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body{
  background-color: white;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(rgb(0, 0, 0), rgb(255, 255, 255)) !important;
  color: fieldtext !important;
}
.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding:2rem
}

.App-link {
  color: #61dafb;
}

.swiperSlideCenter {
  display: flex;
  justify-content: center;
  align-items: center; /* Default alignment */
  background-color: #000;
}
@media screen and (min-width:320px) { 

} 
@media screen and (min-width:480px) { 

} 
@media screen and (min-width:600px) { } 
@media screen and (min-width:768px) { }
 
/* For tablets and above */
@media (min-width: 768px) {
  .swiperSlideCenter {
    align-items: flex-start !important; /* Example: Align items to start on wider screens */
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinAndScale {
  from {
    transform: rotate(0deg) scale(0);
    opacity: 0;
  }
  to {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
}
@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}